import React from 'react'
import { Link } from 'react-router-dom'
const AddPartners = () => {
  return (
    <div className="card">
    <div className="card-body">
        <h5 className="card-title">Add Partners</h5>
        <div className="row">
            <div className="col-6">
                <div className="mb-3">
                    <label className="form-label">Upload logo</label>
                    <input type="file" className="form-control" placeholder="type here" />
                </div>
            </div>
            <div className="col-12">
                <button type="submit" className="btn btn-primary m-2">Submit</button>
               <Link to="/vehicle-info"> <button type="submit" className="btn btn-danger">Cancel</button></Link>
            </div>
        </div>
    </div>
</div>
  )
}

export default AddPartners