import React, { useState } from 'react'
import MediaUploader from '../../components/media-uploader/MediaUploader'
import * as Switch from '@radix-ui/react-switch';

const AddFlights = ({ setOpen }) => {
    const [isActive, setIsActive] = useState(false);

    const handleSwitchChange = (checked) => {
      setIsActive(checked);
    };
    return (
        <div className="card">
            <div className="card-body ">
                <h5 className="card-title border-style">Add Flights</h5>
                <div className="row">

                    <div className="mb-4">
                        <div className="col-12">
                            <div className="mb-3">
                                <label className="form-label">Flight Name</label>
                                <input type="text" className="form-control" placeholder="Type here" />
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="mb-3">
                                <label className="form-label">Flight Code</label>
                                <input type="text" className="form-control" placeholder="Type here" />
                            </div>
                        </div>
                        <div className="col-12 ">
                            <div className="mb-3">
                                <label className="form-label">Logo</label>
                                <MediaUploader
                                // media={media.selfDriveImages}
                                // onUpload={(e) => handleImageUpload(e,'selfDriveImages')}
                                // onRemove={() => removeImage('selfDriveImages')}
                                />
                            </div>
                        </div>
                        <div className="col-12 ">
                            <div className="mb-3">
                                <label className="form-label">Enable For API Search</label>
                                <div className="switch_layout">
                                    <Switch.Root
                                        className="switchRoot"
                                        checked={isActive}
                                        onCheckedChange={handleSwitchChange}
                                    >
                                        <Switch.Thumb className="switchThumb" />
                                    </Switch.Root>
                                    <div className="switch_content">
                                        <p>{isActive ? 'Active' : ''}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <button type="submit" className="btn btn-primary me-3">Submit</button>
                        <button type="button" className="btn btn-danger" onClick={() => setOpen(false)}>Cancel</button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default AddFlights