import React, { useState } from 'react'
import ReactPaginate from 'react-paginate'
import AddFlights from './AddFlights'

const ManageFlights = () => {
    const [open,setOpen] = useState(false)
  return (
    <>
            {open ? (
                <AddFlights setOpen={setOpen} />
            ) : (
                <div className="row">
                    <div className="col">
                        <div className="card">
                            <div className="card-body">
                                <div className="table__add">
                                    <h5 className="card-title">Manage Flights</h5>
                                    <button className='btn btn-primary' onClick={() => setOpen(true)}>Add Flights</button>
                                </div>
                                <div className="search__head">
                                    <div className="count">
                                        <p>Show</p>
                                        <input type="number" className='form-control' value={10} readOnly />
                                        <p>entries</p>
                                    </div>
                                    <div className="search">
                                        <input type="text" className='form-control' placeholder='Search here' />
                                        <i data-feather="search"></i>
                                    </div>
                                </div>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Sl No</th>
                                            <th scope="col">Logo</th>
                                            <th scope="col">Flight Names</th>
                                            <th scope="col">Flight Code</th>
                                          
                                            <th scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        
                                        <tr>
                                            <th>01</th>
                                            <td><img src="https://picsum.photos/seed/picsum/200/300" alt="Attraction" /></td>
                                            <td>Emirates</td>
                                            <th>012434fligGD</th>
                                            
                                            <td className='actions'>
                                                <button className="btn-sm btn-primary"><i data-feather="edit"></i></button>
                                                <button className="btn-sm btn-primary"><i data-feather="trash-2"></i></button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div className="default__pagination">
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel={<i data-feather="chevron-right"></i>}
                                        pageRangeDisplayed={3}
                                        marginPagesDisplayed={2}
                                        pageCount={2}
                                        previousLabel={<i data-feather="chevron-left"></i>}
                                        renderOnZeroPageCount={null}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
  )
}

export default ManageFlights