import React, { useEffect, useState } from 'react'
import ReactQuill from 'react-quill'

import { toolbarOptions } from '../constants/config'
import feather from 'feather-icons';
import MediaUploader from '../components/media-uploader/MediaUploader';



export default function About() {

    const [images, setImages] = useState({
        aboutImageFirst: { file: null, preview: null },
        aboutImageSecond: { file: null, preview: null },
        visionIcon: { file: null, preview: null },
        visionBackgroundImage: { file: null, preview: null },
        missionIcon: { file: null, preview: null },
        missionBackgroundImage: { file: null, preview: null },
        mainContentBanner: { file: null, preview: null }

    });

    const handleImageUpload = (event, type) => {
        const file = event.target.files?.[0];
        if (file) {
            setImages((prevState) => ({
                ...prevState,
                [type]: { file, preview: URL.createObjectURL(file) }
            }));
        }
    };

    const removeImage = (type) => {
        setImages((prevState) => ({
            ...prevState,
            [type]: { file: null, preview: null }
        }));
    };

    useEffect(() => {
        feather.replace();
    }, [])
    return (
        <div className="row">
            <div className="col">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title border-style">Add About</h5>
                        <div className="row">
                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Title</label>
                                    <input type="text" className="form-control" rows={2} placeholder="Type here" />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Short description</label>
                                    <textarea type="text" className="form-control" rows={2} placeholder="Type here" />
                                </div>
                            </div>

                            <div className="col-12 col-md-4">
                                <div className="mb-3">
                                    <label className="form-label">About Page Images(Recommended Size:650*730)</label>
                                    <MediaUploader
                                        media={images.aboutImageFirst}
                                        onUpload={(e) => handleImageUpload(e, 'aboutImageFirst')}
                                        onRemove={() => removeImage('aboutImageFirst')}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="mb-3 preview-imagemargin" >

                                    <MediaUploader
                                        media={images.aboutImageSecond}
                                        onUpload={(e) => handleImageUpload(e, 'aboutImageSecond')}
                                        onRemove={() => removeImage('aboutImageSecond')}
                                    />
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title border-style">Vision</h5>
                        <div className="row">
                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Vision Title</label>
                                    <input type="text" className="form-control" placeholder="Type here" />
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="mb-3">
                                    <label className="form-label">Icon (Recommended Size:650*730)</label>
                                    <MediaUploader
                                        media={images.visionIcon}
                                        onUpload={(e) => handleImageUpload(e, 'visionIcon')}
                                        onRemove={() => removeImage('visionIcon')}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="mb-4">
                                    <label className="form-label">Background Image (Recommended Size:650*730)</label>
                                    <MediaUploader
                                        media={images.visionBackgroundImage}
                                        onUpload={(e) => handleImageUpload(e, 'visionBackgroundImage')}
                                        onRemove={() => removeImage('visionBackgroundImage')}
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Short description</label>
                                    <textarea rows={4} className="form-control" placeholder="Type here"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body ">
                        <h5 className="card-title border-style">Mission</h5>
                        <div className="row">
                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Mission Title</label>
                                    <input type="text" className="form-control" placeholder="Type here" />
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="mb-3">
                                    <label className="form-label">Icon (Recommended Size:650*730)</label>
                                    <MediaUploader
                                        media={images.missionIcon}
                                        onUpload={(e) => handleImageUpload(e, 'missionIcon')}
                                        onRemove={() => removeImage('missionIcon')}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="mb-3">
                                    <label className="form-label">Background Image (Recommended Size:650*730)</label>
                                    <MediaUploader
                                        media={images.missionBackgroundImage}
                                        onUpload={(e) => handleImageUpload(e, 'missionBackgroundImage')}
                                        onRemove={() => removeImage('missionBackgroundImage')}
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Short description</label>
                                    <textarea rows={4} className="form-control" placeholder="Type here"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title border-style">Add Main Contents</h5>
                        <div className="row">
                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Title</label>
                                    <input type="text" className="form-control" rows={2} placeholder="Type here" />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Description</label>
                                    <ReactQuill theme="snow" modules={{
                                        toolbar: toolbarOptions
                                    }}
                                        placeholder='Type here'
                                    />
                                </div>
                            </div>


                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Banner Image (Recommended Size:650*730)</label>
                                    <MediaUploader
                                        media={images.mainContentBanner}
                                        onUpload={(e) => handleImageUpload(e, 'mainContentBanner')}
                                        onRemove={() => removeImage('mainContentBanner')}
                                    />
                                </div>
                                {/* <div className="preview__Grid">
                                    <div className="Item">
                                        <div className="close"><img src="/assets/img/icons/close.svg" alt="" /></div>
                                        <img className='main' src="https://picsum.photos/seed/picsum/200/300" alt="" />
                                    </div>
                                </div> */}
                            </div>



                            <div className="col-12">
                                <button type="submit" className="btn btn-primary m-2">Submit</button>
                                <button type="submit" className="btn btn-danger">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="card">
                    <div className="card-body ">
                        <h5 className="card-title border-style">SEO</h5>
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">Meta Title</label>
                                    <input type="text" className="form-control" placeholder="Type here" />
                                </div>
                            </div>

                            <div className="col-12 col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">Meta Keywords</label>
                                    <input type="text" className="form-control" placeholder="Type here" />
                                </div>
                            </div>
                          
                          
                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Meta description</label>
                                    <ReactQuill theme="snow" modules={{
                                        toolbar: toolbarOptions
                                    }}
                                        placeholder='Type here'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
