import React from 'react';
import * as Tabs from '@radix-ui/react-tabs';
import ReactQuill from 'react-quill'
import { toolbarOptions } from '../../constants/config'
// import { Tab, Tabs } from 'react-bootstrap'
import PackageDetails from './Tabs/PackageDetails'
import ManageOverview from './Tabs/ManageOverview'
import ManageHighlight from './Tabs/ManageHighlight'
import ManageAttraction from './Tabs/ManageAttraction'
import ManageAcivities from './Tabs/ManageAcivities'
import ManageEssential from './Tabs/ManageEssential'
import Gallery from './Tabs/Gallery'
import MediaUploader from '../../components/media-uploader/MediaUploader';

export default function AddPackages() {
    return (
        <div className="row">
            <div className="col">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Add Package </h5>
                        <div className="row">
                            <Tabs.Root className="TabsRoot" defaultValue="tab1">
                                <Tabs.List className="TabsList" aria-label="Manage your account">
                                    <Tabs.Trigger className="TabsTrigger" value="tab1">
                                        Package Details
                                    </Tabs.Trigger>
                                    <Tabs.Trigger className="TabsTrigger" value="tab2">
                                        Overview
                                    </Tabs.Trigger>
                                    <Tabs.Trigger className="TabsTrigger" value="tab3">
                                        Highlights
                                    </Tabs.Trigger>
                                    <Tabs.Trigger className="TabsTrigger" value="tab4">
                                        Attractions
                                    </Tabs.Trigger>
                                </Tabs.List>
                                <Tabs.Content className="TabsContent" value="tab1">

                                    <div className="row mb-4">
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-12 col-md-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">Package ID</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Type here"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-12 col-md-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">Package Name</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Type here"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-12 ">
                                                    <div className="mb-3">
                                                        <label className="form-label">Overview</label>
                                                        <ReactQuill theme="snow" modules={{
                                                            toolbar: toolbarOptions
                                                        }}
                                                            placeholder='Type here'
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-12 col-md-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">Season</label>
                                                        <select className="form-select">
                                                            <option value="">Select Season</option>

                                                            <option value="Summer">
                                                                Summer
                                                            </option>
                                                            <option value="Winter">
                                                                Winter
                                                            </option>
                                                            <option value="Spring">
                                                                Spring
                                                            </option>

                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-md-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">Fixed Package Cost (per adult)</label>
                                                        <input
                                                            type="Number"
                                                            className="form-control"
                                                            placeholder="Type here"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">KLM</label>
                                                        <input
                                                            type="Number"
                                                            className="form-control"
                                                            placeholder="Type here"
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>



                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-12 col-md-4 mb-3">
                                                    <label className="form-label">Banner Image</label>
                                                    <div className="mb-3">
                                                        <MediaUploader />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-4 mb-3">
                                                    <label className="form-label">Banner Image</label>
                                                    <div className="mb-3">
                                                        <MediaUploader />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-4 mb-3">
                                                    <label className="form-label">Banner Image</label>
                                                    <div className="mb-3">
                                                        <MediaUploader />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </Tabs.Content>
                                <Tabs.Content className="TabsContent" value="tab2">

                                </Tabs.Content>
                            </Tabs.Root>


                        </div>

                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">History </h5>
                        <div className="row">

                        </div>
                    </div>
                </div>

            </div>
        </div >
    )
}
