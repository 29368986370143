import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { FaSlidersH } from "react-icons/fa";

import { useTable, useSortBy } from 'react-table';

const data = [
    { slno: '1', bookingNumber: "BQYdjfsdf12", date: "12/10/2025", customerName: "Jhone Due", phone: '93489734732', email: "ldskjf@gmail.com", choosenPackage: "Winter", duration: "6 months" },
    { slno: '2', bookingNumber: "BQYdjfsdf12", date: "12/10/2025", customerName: "Jhone Due", phone: '93489734732', email: "ldskjf@gmail.com", choosenPackage: "Winter", duration: "6 months" },
    { slno: '3', bookingNumber: "BQYdjfsdf12", date: "12/10/2025", customerName: "Jhone Due", phone: '93489734732', email: "ldskjf@gmail.com", choosenPackage: "Winter", duration: "6 months" },
    { slno: '4', bookingNumber: "BQYdjfsdf12", date: "12/10/2025", customerName: "Jhone Due", phone: '93489734732', email: "ldskjf@gmail.com", choosenPackage: "Winter", duration: "6 months" },
    { slno: '5', bookingNumber: "BQYdjfsdf12", date: "12/10/2025", customerName: "Jhone Due", phone: '93489734732', email: "ldskjf@gmail.com", choosenPackage: "Winter", duration: "6 months" },
    { slno: '6', bookingNumber: "BQYdjfsdf12", date: "12/10/2025", customerName: "Jhone Due", phone: '93489734732', email: "ldskjf@gmail.com", choosenPackage: "Winter", duration: "6 months" },

];

const columns = [
    { Header: 'Sl no', accessor: 'slno' },
    { Header: 'Booking Number', accessor: 'bookingNumber' },
    { Header: 'Date', accessor: 'date' },
    { Header: 'Name', accessor: 'customerName' },
    { Header: 'Phone', accessor: 'phone' },
    { Header: 'Email', accessor: 'email' },
    { Header: 'package', accessor: 'choosenPackage' },
    { Header: 'Duration', accessor: 'duration' },
    { Header: 'Action', accessor: 'action', Cell: () => <button className="btn-sm btn-info"><i data-feather="edit"></i></button> }
];
const ManageEnquiries = () => {
    const [open ,setOpen] = useState(false)
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable(
        {
            columns,
            data
        },
        useSortBy
    );

    return (
        <div className="row">
            <div className="col">
                <div className="card ">
                    <div className="card-body">
                        <div className="table__add mb-3">
                            <h5 className="card-title ">Manage Enquiries</h5>
                            <div>
                            <button  className='me-2 filter_btn'><FaSlidersH onClick={()=>setOpen(!open)} size={30}/></button>
                            <Link to='/add-enquiries'><button className='btn btn-primary'>Add Enquiries</button></Link>
                           
                            </div>
                        </div>
                        
                        {open && (
                            <div className="col">
                                <div className="row mb-4"> 
                                 <div className="col-12 col-md-2">
                                    <label className="form-label">Package</label>
                                    <input type="text"className='form-control' placeholder='Search Here' />
                                 </div>
                                 <div className="col-12 col-md-2">
                                    <label className="form-label">Date</label>
                                    <input type="text"className='form-control' placeholder='Search Here' />
                                 </div>
                                 <div className="col-12 col-md-2">
                                    <label className="form-label">Booking</label>
                                    <input type="text"className='form-control' placeholder='Search Here' />
                                 </div>
                                 <div className="col-12 col-md-2">
                                    <label className="form-label">Email</label>
                                    <input type="text"className='form-control' placeholder='Search Here' />
                                 </div>
                                 <div className="col-12 col-md-2">
                                    <label className="form-label">Phone</label>
                                    <input type="text"className='form-control' placeholder='Search Here' />
                                 </div>
                                 <div className="col-12 col-md-2 preview-imagemargin">
                                    
                                    <button className='btn btn-primary '>Search</button>
                                 </div>
                                
                            </div>

                            </div>
                        )}
                        <div className="table-responsive">
                            <table {...getTableProps()} className="react-table table-bordered table-striped table-hover">
                                <thead className="thead-light">
                                    {headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                                            {headerGroup.headers.map(column => (
                                                <th
                                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                                    key={column.id}
                                                    className="text-center"
                                                >
                                                    <div className="d-flex justify-content-between align-items-center ">
                                                        {column.render('Header')}
                                                        {(column.id !== 'action' && column.id !== 'role') && (
                                                            <span className="ml-2 arrow_alignment">
                                                                {column.isSorted ? (
                                                                    column.isSortedDesc ? (
                                                                        <span className="sort-icon">&#9660;</span>
                                                                    ) : (
                                                                        <span className="sort-icon">&#9650;</span>
                                                                    )
                                                                ) : (
                                                                    <>
                                                                        <span className="sort-icon">&#9650;</span>
                                                                        <span className="sort-icon">&#9660;</span>
                                                                    </>
                                                                )}
                                                            </span>
                                                        )}
                                                    </div>
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {rows.map(row => {
                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()} key={row.id}>
                                                {row.cells.map(cell => (
                                                    <td {...cell.getCellProps()} key={cell.column.id}>
                                                        {cell.render('Cell')}
                                                    </td>
                                                ))}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManageEnquiries