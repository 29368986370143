import React, { useState } from 'react'
import ReactQuill from 'react-quill'
import { toolbarOptions } from '../constants/config'
import { AiOutlineClose } from 'react-icons/ai';
import MediaUploader from '../components/media-uploader/MediaUploader';

export default function Curated() {

    const [imagePreviews, setImagePreviews] = useState([]);

    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        const newPreviews = files.map(file => ({
            file,
            preview: URL.createObjectURL(file),
        }));
        setImagePreviews([...imagePreviews, ...newPreviews]);
    };

    const handleRemoveImage = (index) => {
        const newPreviews = imagePreviews.filter((_, i) => i !== index);
        setImagePreviews(newPreviews);
    };

    return (
        <div className="row">
            <div className="col">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Add Curated</h5>
                        <div className="row">
                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Banner Images</label>
                                    <div className="row">
                                        {Array.from({ length: 3 }).map((_, index) => (
                                            <div className='col-12 col-md-4' key={index}>
                                                <MediaUploader
                                                    media={imagePreviews}
                                                    onUpload={(e) => handleImageChange(e, 'curatedImage')}
                                                    onRemove={() => handleRemoveImage('curatedImage')}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Main Heading</label>
                                    <input type="text" className="form-control" placeholder="Type here" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Main Tagline</label>
                                    <textarea className="form-control" rows={2} placeholder="Type here" ></textarea>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Sub Heading</label>
                                    <input type="text" className="form-control" placeholder="Type here" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Short Introduction</label>
                                    <textarea className="form-control" rows={2} placeholder="Type here" ></textarea>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Main Introduction</label>
                                    <ReactQuill theme="snow" modules={{
                                        toolbar: toolbarOptions
                                    }}
                                        placeholder='Type here'
                                    />
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-12 col-md-6 mb-3">
                                    <label className="form-label">Gallery Images</label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        multiple
                                        accept="image/*"
                                        onChange={handleImageChange}
                                    />
                                </div>
                                <div className="col-12 col-md-4 mb-3">
                                    <label className="form-label">Upload Videos</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder='Video url'


                                    />

                                </div>
                                <div className="col-12 col-md-2 mb-3 margin-container">
                                    <button type="submit" className="btn btn-primary m-2">Add</button>
                                </div>
                            </div>

                            <div className="container">
                                <div className="row">
                                    {imagePreviews.length > 0 ? (
                                        imagePreviews.map((item, index) => (
                                            <div className="col-12 col-md-4 col-lg-3 mb-3" key={index}>
                                                <div className="uploader-preview">
                                                    <div className="image-showcontainer">

                                                        <img src={item.preview} alt="Uploaded Preview" className="img-fluid" />

                                                        <AiOutlineClose
                                                            size={30}
                                                            className="remove-icon"
                                                            onClick={() => handleRemoveImage(index)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="col-12">
                                            <div className="empty-placeholder">To previews available</div>
                                        </div>
                                    )}
                                </div>
                            </div>




                            <div className="col-12">
                                <button type="submit" className="btn btn-primary m-2">Submit</button>
                                <button type="submit" className="btn btn-danger">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Add Get in touch</h5>
                        <div className="row">
                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Title</label>
                                    <input type="text" className="form-control" placeholder="Type here" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Image</label>
                                    <input type="file" className="form-control" placeholder="Type here" />
                                </div>
                            </div>
                            <div className="col-12">
                                <button type="submit" className="btn btn-primary m-2">Submit</button>
                                <button type="submit" className="btn btn-danger">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
