import React from 'react'
import Header from '../Header/Header'
import SideBar from '../Sidebar/SideBar'
import { ToastContainer } from 'react-toastify'
import { Outlet } from 'react-router-dom'

export default function Layout() {
    return (
        <div className="page-container">
            <Header />
            <SideBar />
            <div className="page-content">
                <div className="main-wrapper">
                    <Outlet />
                </div>
            </div>
            <ToastContainer autoClose={3000} position='bottom-center' />
        </div>
    )
}
