import React from 'react'

export default function FlightMarkup() {
    return (
       <>
        <div className="row">
            <div className="col">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Flight Markup Cost</h5>
                        <div className="row">
                            <div className="col-12 col-md-7">
                                <div className="mb-3">
                                    <label for="formGroupExampleInput" className="form-label">Flight Markup Cost(%)</label>
                                    <input type="text" className="form-control" placeholder="Type here" />
                                </div>
                            </div>
                          
                            <div className="col-12">
                                <button type="submit" className="btn btn-primary mt-2 me-2">Submit</button>
                                <button type="submit" className="btn btn-danger">Cancel</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        
       </>
    )
}
