import 'bootstrap/dist/css/bootstrap.min.css';


import './App.css';

import RouteHandler from './router/router';

import 'react-quill/dist/quill.snow.css';
import './default.scss';
import { useEffect } from 'react';
import feather from 'feather-icons';
import "react-datepicker/dist/react-datepicker.css";
import { useLocation } from 'react-router-dom';

function App() {
  const location = useLocation()
  useEffect(() => {
    feather.replace();
  }, [location?.pathname])

    return (
    <>

      <RouteHandler />

    </>
  );
}

export default App;
