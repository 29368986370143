export const donutOptions = {
    chart: {
      type: 'doughnut',
    },
    labels: ["Enquiries", "Complaints", "Career","Volunteering"],
    colors: ["#6bcac2", "#9465fcea", "#368bca","#f75d6fd8 "],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    plotOptions: {
      pie: {
        donut: {
          size: '50%', // Size of the donut hole
        },
      },
    },
    dataLabels: {
      dropShadow: {
        enabled: false, // Disable the text shadow
      },
    },
    legend: {
      position: 'bottom',
    },
  };

  export const lineChartOptions = {
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      }
    },
    colors: ['#9465fcea', '#6bcac2','#f75d6fd8', '#368bca'], // Blue for complaints, Green for enquiries
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: [3, 3, 3, 3],
      curve: 'straight',
      dashArray: [0, 0, 0, 0]
    },
    legend: {
      tooltipHoverFormatter: function(val, opts) {
        return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
      }
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6
      }
    },
    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    },
    // yaxis: {
    //   title: {
    //     text: 'Number'
    //   },
    // },
    grid: {
      borderColor: '#f1f1f1',
    }
  }