import React from 'react';

const PaymentMilestones = () => {
  return (
    <div className="row">
      <div className="col">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Payment Milestone</h5>
            <div className="row">
              <div className="col-12">
                <div className="mb-3">
                  <label htmlFor="milestoneInput" className="form-label">Milestone Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="milestoneInput"
                    placeholder="Type here"
                  />
                </div>
              </div>
              <div className="col-12 ">
                <div className="mb-3">
                  <label htmlFor="percentageInput" className="form-label">Percentage(%)</label>
                  <input
                    type="number"
                    className="form-control"
                    id="percentageInput"
                    placeholder="Type here"
                  />
                </div>
              </div>
              <div className="col-12 ">
                <div className="mb-3">
                  <label className="form-label">Description</label>
                  <textarea rows={4} className="form-control" placeholder="Type here"></textarea>
                </div>
              </div>
              <div className="col-12">
                <button type="submit" className="btn btn-primary m-2">
                  Submit
                </button>
                <button type="button" className="btn btn-danger">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentMilestones;
