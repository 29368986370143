import React from 'react'
import MediaUploader from '../../components/media-uploader/MediaUploader'

const AddReview = ({setOpen}) => {
    return (

        <div className="card">
            <div className="card-body ">
                <h5 className="card-title border-style">Add Reviews</h5>
                <div className="row">

                    <div className="mb-4">
                        <div className="col-12">
                            <div className="mb-3">
                                <label className="form-label">Customer Name</label>
                                <input type="text" className="form-control" placeholder="Type here" />
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="mb-3">
                                <label className="form-label">Review</label>
                                <textarea rows={4} className="form-control" placeholder="Type here"></textarea>
                            </div>
                        </div>

                        <div className="col-12 col-md-6">
                            <div className="mb-3">
                                <label className="form-label">Review Rating</label>
                                <input type="number" className="form-control" placeholder="Type here" />
                            </div>
                        </div>
                        <div className="col-12 ">
                            <div className="mb-3">
                                <label className="form-label">Profile Image</label>
                                <MediaUploader
                                // media={media.selfDriveImages}
                                // onUpload={(e) => handleImageUpload(e,'selfDriveImages')}
                                // onRemove={() => removeImage('selfDriveImages')}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                    <button type="submit" className="btn btn-primary m-2">Submit</button>
                    <button type="button" className="btn btn-danger" onClick={() => setOpen(false)}>Cancel</button>
                  </div>

                </div>
            </div>
        </div>
    )
}

export default AddReview