import React from 'react'
import ReactQuill from 'react-quill'
import { toolbarOptions } from '../../constants/config'

export default function AddEssential() {
    return (
        <div className="row">
            <div className="col">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Add Essentail Information </h5>
                        <div className="row">
                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Question</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Type here"
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Answer</label>
                                    <ReactQuill theme="snow" modules={{
                                        toolbar: toolbarOptions
                                    }}
                                        placeholder='Type here'
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <button type="submit" className="btn btn-primary m-2">Submit</button>
                                <button type="submit" className="btn btn-danger">Cancel</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
