import React, { useState } from 'react';
import DatePicker from 'react-datepicker';

const AddEnquiries = () => {
    const [startDate, setStartDate] = useState(new Date()); 
  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title border-style mb-4">Add Enquiries</h5>
        <div className="row">
       
          <div className="col-12 mb-3">
            <div className="row">
              <div className="col-12 col-md-6 mb-3">
                <label className="form-label">Sl No</label>
                <input type="text" className="form-control" placeholder="Type here" />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label className="form-label">Booking Number</label>
                <input type="text" className="form-control" placeholder="Type here" />
              </div>
            </div>
          </div>

       
          <div className="col-12 mb-3">
            <div className="row">
              <div className="col-12 col-md-6 mb-3">
                <label className="form-label">Date/Time</label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)} 
                  className="form-control"
                  placeholderText="Select date"
                  showTimeSelect
                />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label className="form-label">Customer Name</label>
                <input type="text" className="form-control" placeholder="Type here" />
              </div>
            </div>
          </div>

         
          <div className="col-12 mb-3">
            <div className="row">
              <div className="col-12 col-md-6 mb-3">
                <label className="form-label">Email</label>
                <input type="text" className="form-control" placeholder="Type here" />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label className="form-label">Choose Package</label>
                <input type="text" className="form-control" placeholder="Type here" />
              </div>
            </div>
          </div>

        
          <div className="col-12 mb-3">
            <div className="row">
              <div className="col-12 col-md-6 mb-3">
                <label className="form-label">Duration Of Travel</label>
                <input type="text" className="form-control" placeholder="Type here" />
              </div>
            </div>
          </div>

          <div className="col-12">
            <button type="submit" className="btn btn-primary me-3">Submit</button>
            <button type="button" className="btn btn-danger">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddEnquiries;
