
import React, { useState } from 'react'
import ReactQuill from 'react-quill';
import { toolbarOptions } from '../constants/config';


export default function Accommodation() {

    const [value, setValue] = useState('');
    return (
        console.log(value, 'value'),
        <div className="row">
            <div className="col">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Accommodations </h5>
                        <div className="row">
                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Vehicle</label>
                                    <ReactQuill theme="snow" modules={{
                                        toolbar: toolbarOptions
                                    }}
                                        placeholder='type here'
                                        value={value} onChange={setValue} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Answer</label>
                                    <ReactQuill theme="snow" modules={{
                                        toolbar: toolbarOptions
                                    }}
                                        placeholder='type here'
                                        value={value} onChange={setValue} />
                                </div>
                            </div>
                            <div className="col-12">
                                <button type="submit" className="btn btn-primary m-2">Submit</button>
                                <button type="submit" className="btn btn-danger">Cancel</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
