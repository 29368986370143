import React from 'react';

const CostForChild = () => {
    const ageGroups = [
        { label: "Age less than 2 %" },
        { label: "Age between 2 to 5 %" },
        { label: "Age between 5 to 12 %" }
    ];

    return (
        <>
            <div className="row" >
                <div className="col">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Cost For Child</h5>
                            {ageGroups.map((item, index) => (
                                <div className="row" key={index}>
                                    <div className="col-12 col-md-7">
                                        <div className="mb-3">
                                            <label htmlFor={`ageGroupInput${index}`} className="form-label">
                                                {item.label} 
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id={`ageGroupInput${index}`}
                                                placeholder="Type here"
                                            />
                                        </div>
                                    </div>
                                 
                                    <div className="col-12 col-md-1">
                                        <div className="mb-3">
                                        
                                            <div className="actions preview-imagemargin">
                                                <button className="btn-sm btn-primary">
                                                    <i data-feather="edit"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            ))}
                            <div className="col-12">
                                <button type="submit" className="btn btn-primary m-2">Submit</button>
                                <button type="button" className="btn btn-danger">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default CostForChild;
