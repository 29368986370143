
import * as Switch from '@radix-ui/react-switch';
import React, { useState } from 'react';

const CurrencyConversions = () => {
  const [isActive, setIsActive] = useState(false);

  const handleSwitchChange = (checked) => {
    setIsActive(checked);
  };
  const countryCurrencyList = [
    { country: "United States", currencyCode: "USD" },
    { country: "Canada", currencyCode: "CAD" },
    { country: "United Kingdom", currencyCode: "GBP" },
    { country: "European Union", currencyCode: "EUR" },
    { country: "Japan", currencyCode: "JPY" },
    { country: "Australia", currencyCode: "AUD" },
    { country: "India", currencyCode: "INR" },
    { country: "China", currencyCode: "CNY" },
    { country: "Mexico", currencyCode: "MXN" },
    { country: "South Korea", currencyCode: "KRW" },
    { country: "Russia", currencyCode: "RUB" },
    { country: "Brazil", currencyCode: "BRL" },
    { country: "Switzerland", currencyCode: "CHF" },
    { country: "Saudi Arabia", currencyCode: "SAR" },
    { country: "South Africa", currencyCode: "ZAR" },
    { country: "Nigeria", currencyCode: "NGN" },
    { country: "New Zealand", currencyCode: "NZD" },
    { country: "Singapore", currencyCode: "SGD" },
    { country: "United Arab Emirates", currencyCode: "AED" },
    { country: "Turkey", currencyCode: "TRY" }
  ];
  return (
    <div className="row">
      <div className="col">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Currency Conversions</h5>
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="mb-3">
                  <label htmlFor="milestoneInput" className="form-label">Currency Code</label>
                  <select className="form-select">
                    <option value="">Select Country</option>
                    {countryCurrencyList.map((item, index) => (
                      <option key={index} value={item.currencyCode}>
                        {item.country} - {item.currencyCode}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-12">
                <div className="mb-3">
                  <label htmlFor="conversionRateInput" className="form-label">Conversion Rate (Related To USD)</label>
                  <input
                    type="number"
                    className="form-control"
                    id="conversionRateInput"
                    placeholder="Type here"
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="mb-3">
                  <label className="form-label">Display in Website</label>
                  <div className="switch_layout">
                    <Switch.Root
                      className="switchRoot"
                      checked={isActive}
                      onCheckedChange={handleSwitchChange}
                    >
                      <Switch.Thumb className="switchThumb" />
                    </Switch.Root>
                    <div className="switch_content">
                      <p>{isActive ? 'Active' : ''}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 mt-2 ">
                <button type="submit" className="btn btn-primary me-2 ">Submit</button>
                <button type="button" className="btn btn-danger">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrencyConversions;
