import React from 'react'
import ReactPaginate from 'react-paginate'
import { Link } from 'react-router-dom'

const ManagePayment = () => {
  return (
    <div className="row">
    <div className="col">
        <div className="card">
            <div className="card-body">
                <div className="table__add">
                    <h5 className="card-title">Manage Payment Mile Stone</h5>
               
                 <Link to='/add-milestone'><button className='btn btn-primary'>Add Payment Mile Stone</button></Link>
                 
                </div>
                <div className="search__head">
                    <div className="count">
                        <p>Show</p>
                        <input type="number" className='form-control' value={10} />
                        <p>entries</p>
                    </div>
                    <div className="search">
                        <input type="text" className='form-control' placeholder='Search here' />
                        <i data-feather="search"></i>
                    </div>
                </div>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">SL.No</th>
                            <th className='long_text'>xxxxxxxx</th>
                            <th className='long_text'>xxxxxxxx</th>
                            <th className='long_text'>xxxxxxxx</th>
                            <th className='long_text'>xxxxxxxx</th>
                            <th className='long_text'>xxxxxxxx</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>1</th>
                            <th>xxxxxxxx</th>
                            <th>xxxxxxxx</th>
                            <th>xxxxxxxx</th>
                            <th>xxxxxxxx</th>
                            <th>xxxxxxxx</th>
                            <td className='actions'>
                                <button className="btn-sm btn-primary"><i data-feather="edit"></i></button>
                                <button className="btn-sm btn-primary"><i data-feather="trash-2"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div className="default__pagination">
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel={<i data-feather="chevron-right"></i>}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={2}
                        previousLabel={<i data-feather="chevron-left"></i>}
                        renderOnZeroPageCount={null}
                    />
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default ManagePayment