import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import { AiOutlineClose, AiOutlinePlus } from 'react-icons/ai';
import MediaUploader from "../../components/media-uploader/MediaUploader";
import IconUploader from '../../components/media-uploader/IconUploader';
import CommonTitleCard from '../../components/CommonTitleCard';
import ModalFooter from 'react-bootstrap/esm/ModalFooter';


const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
];

const Home = () => {
    const [openAttraction, setOpenAttraction] = useState(false)
    const [openList, setOpenList] = useState(false)
    const [selectedOption, setSelectedOption] = useState(null);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [icon, setIcon] = useState(null);
    const [list, setList] = useState([]);

    const [openReviews, setOpenReviews] = useState(false)

    const [media, setMedia] = useState({
        bannerVideo: { file: null, preview: null },
        featureIcons: Array.from({ length: 6 }, () => ({ file: null, preview: null })),

    });

    const handleImageUpload = (event, type, index = null) => {
        const file = event.target.files?.[0];
        if (file) {
            if (type === 'featureIcons' && index !== null) {
                setMedia((prevState) => {
                    const updatedIcons = [...prevState.featureIcons];
                    updatedIcons[index] = { file, preview: URL.createObjectURL(file) };
                    return { ...prevState, featureIcons: updatedIcons };
                });
            } else {
                setMedia((prevState) => ({
                    ...prevState,
                    [type]: { file, preview: URL.createObjectURL(file) }
                }));
            }
        }
    };

    const removeImage = (type, index = null) => {
        if (type === 'featureIcons' && index !== null) {
            setMedia((prevState) => {
                const updatedIcons = [...prevState.featureIcons];
                updatedIcons[index] = { file: null, preview: null };
                return { ...prevState, featureIcons: updatedIcons };
            });
        } else {
            setMedia((prevState) => ({
                ...prevState,
                [type]: { file: null, preview: null }
            }));
        }
    };

    const handleDataList = () => {
        if (title.trim() === '' || description.trim() === '') {
            alert("Title and Description can't be empty");
            return;
        }

        const newListItem = {
            title,
            description,
            icon,
        };


        setList((prevList) => [...prevList, newListItem]);
        handleCloseList();


        setTitle('');
        setDescription('');
        setIcon(null);
    };
    const handleOpenReviews = () => setOpenReviews(true)
    const handleCloseReviews = () => setOpenReviews(false)

    const handleClose = () => setOpenAttraction(false);
    const handleOpenAttraction = () => setOpenAttraction(true);
    const handleOpenList = () => setOpenList(true)
    const handleCloseList = () => setOpenList(false);


    return (

        /* Banner section*/

        <div className="row">
            <div className="col">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title border-style">Banner</h5>
                        <div className="row">
                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Intro Text</label>
                                    <input type="text" className="form-control" rows={2} placeholder="Type here" />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Banner Video (Recommeneded Size:650*730)</label>
                                    <MediaUploader
                                        media={media.bannerVideo}
                                        onUpload={(e) => handleImageUpload(e, 'bannerVideo')}
                                        onRemove={() => removeImage('bannerVideo')}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                {/* Feature icons sections */}
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title border-style">Feature List</h5>
                        <div className="row">
                            {Array.from({ length: 6 }).map((_, index) => (
                                <React.Fragment key={index}>
                                    <div className="col-12 col-md-2">
                                        <div className="mb-3">
                                            <IconUploader
                                                media={media.featureIcons[index]}
                                                onUpload={(e) => handleImageUpload(e, 'featureIcons', index)}
                                                onRemove={() => removeImage('featureIcons', index)}
                                                index={index}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-10">
                                        <div className="mb-4">
                                            <label className="form-label">{index + 1}.Feature Name</label>
                                            <input type="text" className="form-control" placeholder="Type here" />
                                        </div>
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                </div>
                {/* About section */}

                <div className="card">
                    <div className="card-body ">
                        <h5 className="card-title border-style">About</h5>
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <div className="mb-3">
                                    <label className="form-label">About Image (Recommeneded Size:650*730)</label>
                                    <MediaUploader
                                        media={media.aboutImage}
                                        onUpload={(e) => handleImageUpload(e, 'aboutImage')}
                                        onRemove={() => removeImage('aboutImage')}
                                    />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="mb-3">
                                    <label className="form-label">Main Heading</label>
                                    <input type="text" className="form-control" rows={2} placeholder="Type here" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Short description</label>
                                    <textarea rows={4} className="form-control" placeholder="Type here"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* tagline section */}

                <CommonTitleCard MainHeading="Tagline" />

                {/* self drive tours */}


                <div className="card">
                    <div className="card-body ">
                        <h5 className="card-title border-style">Self Drive Tours </h5>
                        <div className="row">
                            <div className="col-12 ">
                                <div className="mb-3">
                                    <label className="form-label">Main Heading </label>
                                    <input type="text" className="form-control" placeholder="Type here" />
                                </div>
                            </div>

                            <div className="col-12 ">
                                <div className="mb-3">
                                    <label className="form-label">Short Description</label>
                                    <textarea rows={4} className="form-control" placeholder="Type here"></textarea>
                                </div>
                            </div>


                            <div className="col-12 col-md-4">
                                <div className="mb-3">
                                    <label className="form-label"> Background Image (Recommeneded Size:650*730)</label>
                                    <MediaUploader
                                        media={media.selfDriveImages}
                                        onUpload={(e) => handleImageUpload(e, 'selfDriveImages')}
                                        onRemove={() => removeImage('selfDriveImages')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* popular packages  */}

                <CommonTitleCard MainHeading="Popular Packages" />

                {/* Attractions */}

                <CommonTitleCard MainHeading="Attractions" />

                {/* Adding Attractions */}

                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title border-style">Adding Attractions</h5>
                        <div className="row">
                            {openAttraction ? (
                                <Modal show={openAttraction} onHide={handleClose} centered>
                                    <Modal.Header closeButton className='modal_design'>
                                        <Modal.Title>Choose Attractions</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body className='mb-5 mt-4'>
                                        <Select
                                            defaultValue={selectedOption}
                                            onChange={setSelectedOption}
                                            options={options}
                                        />
                                    </Modal.Body>

                                </Modal>

                            ) : (
                                Array.from({ length: 8 }).map((_, index) => (
                                    <div className="col-12 col-md-3 mb-3" key={index}>

                                        <div className="uploader-container mb-3">
                                            <label className="upload-label">
                                                <AiOutlinePlus size={40} className="plus-icon" onClick={handleOpenAttraction} />
                                            </label>
                                            <div className='index_number'>
                                                <p>{index + 1}</p>
                                            </div>
                                        </div>
                                    </div>
                                )))


                            }

                        </div>
                    </div>
                </div>

                {/* why choose us     */}

                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title border-style">Why Choose Us</h5>
                        <div className="row">
                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Main Heading</label>
                                    <input type="text" className="form-control" rows={2} placeholder="Type here" />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Tag Line</label>
                                    <input type="text" className="form-control" rows={2} placeholder="Type here" />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">List</label>
                                    <div className="row">

                                        {list.length > 0 && (
                                            <>
                                                {list.map((item, index) => (
                                                    <div key={index} className="col-12 col-md-3 mb-3">
                                                        <div className="card card_layout">
                                                            <div className="card-body">
                                                                <h5 className="card-title mb-2">{item.title}</h5>
                                                                <p className="card-text mb-0">{item.description}</p>
                                                                <AiOutlineClose size={30} className='remove-icon' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </>

                                        )}


                                        <div className="col-12 col-md-3">
                                            <div className="add_list mb-3">
                                                <label className="upload-label">
                                                    <AiOutlinePlus size={40} className="plus-icon" onClick={handleOpenList} />
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <Modal show={openList} onHide={handleCloseList} centered >
                                        <Modal.Header closeButton className='modal_design'>
                                            <Modal.Title>Add List</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body className='mt-4'>

                                            <div className="col-12 ">
                                                <div className="mb-3">
                                                    <label className="form-label">Title</label>
                                                    <input type="text" className="form-control" onChange={(e) => setTitle(e.target.value)} placeholder="Type here" />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="mb-3">
                                                    <label className="form-label">Short Description</label>
                                                    <textarea rows={4} onChange={(e) => setDescription(e.target.value)} className="form-control" placeholder="Type here"></textarea>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-3">
                                                <div className="mb-5">
                                                    <label className="form-label">Icon</label>
                                                    <IconUploader
                                                        media={icon ? { preview: URL.createObjectURL(icon) } : null}
                                                        onUpload={(e) => setIcon(e.target.files[0])}
                                                        onRemove={() => setIcon(null)}
                                                    />
                                                </div>
                                            </div>
                                        </Modal.Body>
                                        <ModalFooter>
                                            <Button onClick={handleDataList}>Add</Button>
                                        </ModalFooter>
                                    </Modal>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* Review section */}
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title border-style">Reviews</h5>
                        <div className="row">
                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Main Heading</label>
                                    <input type="text" className="form-control" rows={2} placeholder="Type here" />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Tag Line</label>
                                    <input type="text" className="form-control" rows={2} placeholder="Type here" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Add Reviews</label>
                                    {openReviews ? (
                                        <Modal show={openReviews} onHide={handleCloseReviews} centered>
                                            <Modal.Header closeButton className='modal_design'>
                                                <Modal.Title>Choose Reviews</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body className='mb-5 mt-4'>
                                                <Select
                                                    defaultValue={selectedOption}
                                                    onChange={setSelectedOption}
                                                    options={options}
                                                />
                                            </Modal.Body>

                                        </Modal>

                                    ) : (

                                        <div className="col-12 col-md-3">
                                            <div className="add_list mb-3">
                                                <label className="upload-label">
                                                    <AiOutlinePlus size={40} className="plus-icon" onClick={handleOpenReviews} />
                                                </label>
                                            </div>
                                        </div>

                                    )


                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                {/* 
          curated section */}
                <div className="card">
                    <div className="card-body ">
                        <h5 className="card-title border-style">Curated</h5>
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <div className="mb-3">
                                    <label className="form-label">Curated Image</label>
                                    <MediaUploader
                                        media={media.aboutImage}
                                        onUpload={(e) => handleImageUpload(e, 'curatedImage')}
                                        onRemove={() => removeImage('curatedImage')}
                                    />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="mb-3">
                                    <label className="form-label">Main Heading</label>
                                    <input type="text" className="form-control" rows={2} placeholder="Type here" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Short description</label>
                                    <textarea rows={4} className="form-control" placeholder="Type here"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* blog section */}
                <CommonTitleCard MainHeading="Blog" />
            </div>
        </div>
    )
}

export default Home