import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Dashboard from '../pages/Dashboard'
import HotelRooms from '../pages/Cost Settings/HotelRooms'
import FlightMarkup from '../pages/Cost Settings/FlightMarkup'
// import Activities from '../pages/Activity/Activities'
// import Attractions from '../pages/Attractions/Attractions'
import ManageEssential from '../pages/Essential Info/ManageEssential'
import AddEssential from '../pages/Essential Info/AddEssential'
import ManageActivity from '../pages/Activity/ManageActivity'
import ManageAttraction from '../pages/Attractions/ManageAttraction'
import ManageInclusions from '../pages/Settings/ManageInclusions'
import ManageExclusions from '../pages/Settings/ManageExclusions'
import Accommodation from '../pages/Accommodation'
import Questionnaire from '../pages/Questionnaire'
import Layout from '../components/Layout/Layout'
import Login from '../pages/Login/Login'
import AddPermission from '../pages/Permission/AddPermission'
import ManagePermissions from '../pages/Permission/ManagePermissions'
import Blog from '../pages/Blogs/Blog'
import ManageBlogs from '../pages/Blogs/ManageBlogs'
import Vehicle from '../pages/Cost Settings/Vehicle'
import Seasons from '../pages/Seasons/Seasons'
import ManageSeasons from '../pages/Seasons/ManageSeasons'
import About from '../pages/About'
import Curated from '../pages/Curated'
import VehicleInfo from '../pages/Vehicle Info/VehicleInfo'

import AddDescription from '../pages/Essential Info/AddDescription'
import ManagePackages from '../pages/Packages/ManagePackages'
import AddPackages from '../pages/Packages/AddPackages'
import Home from '../pages/Home/Home'
import ManageReviews from '../pages/Reviews/ManageReviews'
import ManageFlights from '../pages/Flight Settings/ManageFlights'
import AddVehicle from '../pages/Vehicle Info/AddVehicle'
import AddPartners from '../pages/Partners Info/AddPartners'
import HotelExperiance from '../pages/Hotel Experiance/HotelExperiance'
import CostForChild from '../pages/Cost Settings/CostForChild'
import PaymentMilestones from '../pages/Payment Mile Stone/PaymentMilestones'
import CurrencyConversions from '../pages/CurrencyConversions';
import ManagePayment from '../pages/Payment Mile Stone/ManagePayment'
import ManageCurrrencyConversion from '../pages/Currency Conversion/ManageCurrrencyConversion'
import ManageEnquiries from '../pages/Enquiries/ManageEnquiries'
import AddEnquiries from '../pages/Enquiries/AddEnquiries'



function RouteHandler() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route element={<Layout />} >
        <Route path="/" element={<Dashboard />} />
        <Route path="/room" element={<HotelRooms />} />
        <Route path="/vehicle" element={<Vehicle />} />
        <Route path="/flight-markup" element={<FlightMarkup />} />
        <Route path="/manage-activity" element={<ManageActivity />} />
        <Route path="/manage-reviews" element={<ManageReviews />} />
        <Route path="/manage-attraction" element={<ManageAttraction />} />
        <Route path="/manage-flights" element={<ManageFlights/>} />
        <Route path="/manage-essential" element={<ManageEssential />} />
        <Route path="/add-description" element={<AddDescription />} />
        <Route path="/add-essential" element={<AddEssential />} />
        <Route path="/manage-inclusions" element={<ManageInclusions />} />
        <Route path="/manage-exclutions" element={<ManageExclusions />} />
        <Route path="/accommodation" element={<Accommodation />} />
        <Route path="/questionnaire" element={<Questionnaire />} />
        <Route path="/add-permission" element={<AddPermission />} />
        <Route path="/manage-permission" element={<ManagePermissions />} />
        <Route path="/add-blog" element={<Blog />} />
        <Route path="/manage-blogs" element={<ManageBlogs />} />
        <Route path="/add-season" element={<Seasons />} />
        <Route path="/manage-season" element={<ManageSeasons />} />
        <Route path="/home" element = {<Home/>}/>
        <Route path="/about" element={<About />} />
        <Route path="/curated" element={<Curated />} />
        <Route path="/vehicle-info" element={<VehicleInfo />} />
        <Route path="/add-vehicle" element={<AddVehicle />} />
        
        <Route path="/add-partners" element={<AddPartners />} />
        <Route path="/hotel-experiance" element={<HotelExperiance />} />
        <Route path="/cost-child" element={<CostForChild/>} />
        <Route path="/payment-milestone" element={<ManagePayment/>} />
        <Route path="/add-milestone" element={<PaymentMilestones />} />
        <Route path="/currency-conversion" element={<ManageCurrrencyConversion/>} />
        <Route path="/add-conversion" element={<CurrencyConversions/>} />
        
        <Route path="/manage-enquiries" element={<ManageEnquiries/>}/>
        <Route path="//add-enquiries" element={<AddEnquiries/>}/>
        
        

        <Route path="/manage-packages" element={<ManagePackages />} />
        <Route path="/add-package" element={<AddPackages />} />
      </Route>
    </Routes>
  )
}

export default RouteHandler