import React, { useState } from 'react'
import { Root, List, Trigger, Content } from '@radix-ui/react-tabs';
import { RiDeleteBin6Line } from "react-icons/ri";
import MediaUploader from '../../components/media-uploader/MediaUploader'
import { AiOutlineClose } from 'react-icons/ai';


const HotelExperience = () => {
   const [imagePreviews, setImagePreviews] = useState([]);
   const [logoPreviews, setLogoPreviews] = useState([]);
   const [description, setDescription] = useState([])
   const [inputDescription, setInputDescription] = useState("")

   const handleChange = (e) => {
      setInputDescription(e.target.value)
   }
   const handleDescription = () => {
      if (inputDescription.trim() !== "")
         setDescription((prev) => [...prev, inputDescription.trim()])
      setInputDescription("")
   }


   const handleDelete = (indexToDelete) => {
      setDescription(description.filter((_, index) => index !== indexToDelete));
   };

   const handleLogoUpload = (e) => {
      const files = Array.from(e.target.files);
      const newLogoPreviews = files.map(file => ({
         file,
         preview: URL.createObjectURL(file),
      }));
      setLogoPreviews([...logoPreviews, ...newLogoPreviews]);
   };


   const handleImageChange = (e) => {
      const files = Array.from(e.target.files);
      const newImagePreviews = files.map(file => ({
         file,
         preview: URL.createObjectURL(file),
      }));
      setImagePreviews([...imagePreviews, ...newImagePreviews]);
   };


   const handleRemoveLogo = (index) => {
      const newLogoPreviews = logoPreviews.filter((_, i) => i !== index);
      setLogoPreviews(newLogoPreviews);
   };


   const handleRemoveImage = (index) => {
      const newPreviews = imagePreviews.filter((_, i) => i !== index);
      setImagePreviews(newPreviews);
   };

   return (

      <div className="row">
         <div className="col">
            <div className="card">
               <div className="card-body">

                  <Root className="TabsRoot" defaultValue="tab1">
                     <List className="TabsList" aria-label="Manage your account">
                        <Trigger className="TabsTrigger" value="tab1">
                           Hotel Title And Description
                        </Trigger>
                        <Trigger className="TabsTrigger" value="tab2">
                           Partners Logo
                        </Trigger>
                        <Trigger className="TabsTrigger" value="tab3">
                           Gallary Images
                        </Trigger>
                     </List>
                     <Content className="TabsContent" value="tab1">


                        <h5 className="card-title mb-3">Hotel Experiance Description</h5>
                        <div className="row">
                           <div className="col-12">
                              <div className="mb-3">
                                 <label className="form-label">Hotel Tagline</label>
                                 <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Type here"
                                 />
                              </div>
                           </div>
                           <div className="col-12">
                              <div className="mb-3">
                                 <label className="form-label">Intro Image (Recommended Size: 650*730)</label>
                                 <MediaUploader
                                 // media={media.bannerVideo}
                                 // onUpload={(e) => handleImageUpload(e, 'bannerVideo')}
                                 // onRemove={() => removeImage('bannerVideo')}
                                 />
                              </div>
                           </div>


                           {description.length > 0 && (
                              <div>
                                 <label className="form-label">Description</label>
                                 {description.map((desc, index) => (
                                    <div className="row" key={index}>
                                       <div className="col-12 col-md-6">
                                          <div className="mb-3">
                                             <input
                                                type="text"
                                                className="form-control"
                                                value={desc}
                                                readOnly
                                             />
                                          </div>
                                       </div>
                                       <div className="col-12 col-md-1">
                                          <div className="mb-3">
                                             <RiDeleteBin6Line
                                                size={30}
                                                onClick={() => handleDelete(index)}
                                                className='delete_icon'
                                             />
                                          </div>
                                       </div>



                                    </div>
                                 ))}
                              </div>
                           )}


                           <div className="row mt-2">
                              <div className="col-12 col-md-6 pe-1">
                                 <div className="mb-3">
                                    {description.length === 0 && (
                                       <label className="form-label">Description</label>
                                    )}
                                    <input
                                       type="text"
                                       className="form-control"
                                       value={inputDescription}
                                       onChange={handleChange}
                                       placeholder="Type here"
                                    />
                                 </div>
                              </div>
                              <div className="col-12 col-md-1">
                                 <div className={`mb-3 ${description.length > 0 ? "" : "preview-imagemargin"}`}>
                                    <button
                                       onClick={handleDescription}
                                       className="btn btn-primary"
                                    >
                                       Add
                                    </button>
                                 </div>
                              </div>
                           </div>
                           <div className="col-12 mt-2">
                              <button type="submit" className="btn btn-primary me-3">Submit</button>
                              <button type="button" className="btn btn-danger" >Cancel</button>
                           </div>
                        </div>


                     </Content>
                     <Content className="TabsContent" value="tab2">
                        <h5 className="card-title mb-3">Partners Logo</h5>
                        <div className="row mb-4">
                           {logoPreviews.map((media, index) => (
                              <div key={index} className="col-3 preview-imagemargin">
                                 <MediaUploader
                                    media={media}
                                    onRemove={() => handleRemoveLogo(index)}
                                 />
                              </div>
                           ))}

                           <div className="col-3">
                              <div className="mb-3">
                                 <label className="form-label"> Add Logo</label>
                                 <MediaUploader
                                    onUpload={handleLogoUpload}
                                 />
                              </div>
                           </div>

                           <div className="col-12 mt-2">
                              <button type="submit" className="btn btn-primary me-3">Submit</button>
                              <button type="button" className="btn btn-danger">Cancel</button>
                           </div>
                        </div>
                     </Content>
                     <Content className="TabsContent" value="tab3">
                     <h5 className="card-title mb-3">Gallary Images</h5>
                        <div className="row">
                           <div className="col-12 col-md-6 mb-3">
                              <label className="form-label">Gallery Images</label>
                              <input
                                 type="file"
                                 className="form-control"
                                 multiple
                                 accept="image/*"
                                 onChange={handleImageChange}
                              />
                           </div>
                           <div className="col-12 col-md-4 mb-3">
                              <label className="form-label">Upload Videos</label>
                              <input
                                 type="text"
                                 className="form-control"
                                 placeholder="Video URL"
                              />
                           </div>
                           <div className="col-12 col-md-2 mb-3 margin-container">
                              <button type="submit" className="btn btn-primary m-2">Add</button>
                           </div>
                        </div>

                        <div className="container">
                           <div className="row">
                              {imagePreviews.length > 0 ? (
                                 imagePreviews.map((item, index) => (
                                    <div className="col-12 col-md-4 col-lg-3 mb-3" key={index}>
                                       <div className="uploader-preview">
                                          <div className="image-showcontainer">
                                             <img src={item.preview} alt="Uploaded Preview" className="img-fluid" />
                                             <AiOutlineClose
                                                size={30}
                                                className="remove-icon"
                                                onClick={() => handleRemoveImage(index)}
                                             />
                                          </div>
                                       </div>
                                    </div>
                                 ))
                              ) : (
                                 <div className="col-12">
                                    <div className="empty-placeholder">No previews available</div>
                                 </div>
                              )}
                           </div>
                        </div>

                        <div className="col-12 mt-2">
                           <button type="submit" className="btn btn-primary me-3">Submit</button>
                           <button type="button" className="btn btn-danger">Cancel</button>
                        </div>
                     </Content>

                  </Root>
               </div>
            </div>
         </div>
      </div>
   );
}

export default HotelExperience







