
import ReactQuill from 'react-quill'
import { toolbarOptions } from '../../constants/config'


export default function Activities({setOpen}) {

  return (
    <div className="row">
      <div className="col">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Add Activity</h5>
            <div className="row">
              <div className="col-12">
                <div className="mb-3">
                  <label className="form-label">Title</label>
                  <input type="text" className="form-control" placeholder="type here" />
                </div>
              </div>
              <div className="col-6">
                <div className="mb-3">
                  <label className="form-label">Image</label>
                  <input type="file" className="form-control" placeholder="type here" />
                </div>
              </div>
              <div className="col-6">
                <div className="mb-3">
                  <label className="form-label">Duration</label>
                  <input type="text" className="form-control" placeholder="type here" />
                </div>
              </div>
              <div className="col-12">
                <div className="mb-3">
                  <label className="form-label">Long Description</label>
                  <ReactQuill theme="snow" modules={{
                    toolbar: toolbarOptions
                  }}
                    placeholder='type here'
                  />
                </div>
              </div>
              <div className="col-6">
                <label className="form-label">Billable Activity</label>
                <fieldset className="row mb-3">
                  <div className="col-sm-10 d-flex gap-10" style={{ gap: "20px" }}>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="gridRadios" id="yes" value="yes" checked />
                      <label className="form-check-label" for="yes">
                        Yes
                      </label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="no" id="gridRadios2" value="no" />
                      <label className="form-check-label" for="no">
                        No
                      </label>
                    </div>
                  </div>
                </fieldset>
              </div>
              <div className="col-12">
                <button type="submit" className="btn btn-primary m-2">Submit</button>
                <button type="submit" className="btn btn-danger" onClick={()=>setOpen(false)}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
