import { Root, List, Trigger, Content } from '@radix-ui/react-tabs';

import React from 'react'
import ReactPaginate from 'react-paginate'
import { Link } from 'react-router-dom'

 function VehicleInfo() {
    return (
        <div className="row">
            <div className="col">
              
            <div className="card">
                            <div className="card-body">
                <Root className="TabsRoot" defaultValue="tab1">
                    <List className="TabsList" aria-label="Manage your account">
                        <Trigger className="TabsTrigger" value="tab1">
                            Manage Vehicle
                        </Trigger>
                        <Trigger className="TabsTrigger" value="tab2">
                            Manage Partners
                        </Trigger>

                    </List>
                    <Content className="TabsContent" value="tab1">


                                <div className="table__add">
                                    <h5 className="card-title">Manage Vehicle</h5>
                                    <Link to="/add-vehicle"> <button className='btn btn-primary'>Add Vehicle</button></Link>
                                </div>
                                <div className="search__head">
                                    <div className="count">
                                        <p>Show</p>
                                        <input type="number" className='form-control' value={10} />
                                        <p>entries</p>
                                    </div>
                                    <div className="search">
                                        <input type="text" className='form-control' placeholder='Search here' />
                                        <i data-feather="search"></i>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table full-title">
                                        <thead>
                                            <tr>
                                                <th>Sl.No</th>
                                                <th>Image</th>
                                                <th>Vehicle Name</th>
                                                <th>Included in package cost*</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th>001</th>
                                                <td><img src="https://picsum.photos/seed/picsum/200/300" alt="" /></td>
                                                <td>Jane</td>
                                                <td><span className="badge bg-primary">Yes</span></td>
                                                <td className='actions'>
                                                    <button className="btn-sm btn-primary"><i data-feather="edit"></i></button>
                                                    <button className="btn-sm btn-primary"><i data-feather="trash-2"></i></button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div className="default__pagination">
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel={<i data-feather="chevron-right"></i>}
                                        pageRangeDisplayed={3}
                                        marginPagesDisplayed={2}
                                        pageCount={2}
                                        previousLabel={<i data-feather="chevron-left"></i>}
                                        renderOnZeroPageCount={null}
                                    />
                                </div>
                           
                    </Content>
                    <Content className="TabsContent" value="tab2">
                                <div className="table__add">
                                    <h5 className="card-title">Manage Partners</h5>
                                    <Link to="/add-partners"> <button className='btn btn-primary'>Add Partners</button> </Link>

                                </div>
                                <div className="search__head">
                                    <div className="count">
                                        <p>Show</p>
                                        <input type="number" className='form-control' value={10} />
                                        <p>entries</p>
                                    </div>
                                    <div className="search">
                                        <input type="text" className='form-control' placeholder='Search here' />
                                        <i data-feather="search"></i>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table full-title">
                                        <thead>
                                            <tr>
                                                <th>Sl.No</th>
                                                <th>Partner Logo</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th>001</th>
                                                <td><img src="https://picsum.photos/seed/picsum/200/300" alt="" /></td>
                                                <td className='actions'>
                                                    <button className="btn-sm btn-primary"><i data-feather="edit"></i></button>
                                                    <button className="btn-sm btn-primary"><i data-feather="trash-2"></i></button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div className="default__pagination">
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel={<i data-feather="chevron-right"></i>}
                                        pageRangeDisplayed={3}
                                        marginPagesDisplayed={2}
                                        pageCount={2}
                                        previousLabel={<i data-feather="chevron-left"></i>}
                                        renderOnZeroPageCount={null}
                                    />
                                </div>
                          
                    </Content>
                </Root>
                </div>
                </div>
            </div>
        </div>
    )
}

export default VehicleInfo












