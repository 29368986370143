import { Link } from "react-router-dom"

const AddVehicle = () => {
  return (
    <div className="card">
    <div className="card-body">
        <h5 className="card-title">Add Vehicle</h5>
        <div className="row">
            <div className="col-6">
                <div className="mb-3">
                    <label className="form-label">Add Vehicle Image</label>
                    <input type="file" className="form-control" placeholder="Type here" />
                </div>
            </div>
            <div className="col-6">
                <div className="mb-3">
                    <label className="form-label">Add Vehicle Name</label>
                    <input type="text" className="form-control" placeholder="Type here" />
                </div>
            </div>
            <div className="col-6">
                <label className="form-label">Included in package cost*</label>
                <fieldset className="row mb-3">
                    <div className="col-sm-10 d-flex gap-10" style={{ gap: "20px" }}>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="yes" />
                            <label class="form-check-label" for="yes">
                                Yes
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="no" checked />
                            <label class="form-check-label" for="no">
                                No
                            </label>
                        </div>
                    </div>
                </fieldset>
            </div>
            <div className="col-12">
                <button type="submit" className="btn btn-primary m-2">Submit</button>
                <Link to="/vehicle-info"><button type="submit" className="btn btn-danger">Cancel</button></Link>
            </div>
        </div>
    </div>
</div>
  )
}

export default AddVehicle